html,
body,
#root {
  height: 100%; }

body {
  font-family: "Microsoft JhengHei"; }

.container-fluid {
  padding: 0; }

#header {
  height: 7.5%; }

#body {
  height: 82.5%; }

#footer {
  height: 10%; }
